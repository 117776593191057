/*
 * Control the gallery behavior
 */
((document) => {
    const $wrapper = document.getElementById('gallery');
    if ($wrapper.length === 0) return;

    const slug = $wrapper.getAttribute('data-slug');
    const $slides = $wrapper.querySelectorAll('.gallery__slide');
    const $btnPrev = document.getElementById('gallery-prev');
    const $btnNext = document.getElementById('gallery-next');
    const homepage = slug === 'homepage';

    let currentSlide = parseInt($wrapper.getAttribute('data-start'), 10) - 1 || 0;

    // Show the first one
    $slides[currentSlide].classList.toggle('active');

    /*
     * Resize the images to keep them centered and fitting within the screen
     */
    function updateImageSizes() {
        const wrapperWidth = $wrapper.clientWidth;
        const wrapperHeight = $wrapper.clientHeight;

        Array.prototype.forEach.call($slides, (el) => {
            if (el.style.display === 'block') {
                const $img = el.querySelectorAll('img');
                const $figure = el.querySelector('figure');

                if ($img.length && $figure) {
                    let height = 1000;
                    let totalWidth = ($img.length - 1) * 12; // 12 = gap between images

                    // Collect the total height and width of the images
                    Array.prototype.forEach.call($img, (img) => {
                        totalWidth += img.getAttribute('width') / img.getAttribute('height') * 1000;
                    });

                    if (totalWidth / height > wrapperWidth / wrapperHeight) {
                        height = Math.round(height / totalWidth * wrapperWidth);
                        totalWidth = wrapperWidth;
                    } else {
                        totalWidth = Math.round(totalWidth / height * wrapperHeight);
                        height = wrapperHeight;
                    }

                    $figure.style.height = height + 'px';
                    $figure.style.width = totalWidth + 'px';
                }
            }
        });
    }

    function resizeHandler() {
        window.requestAnimationFrame(updateImageSizes);
    }

    function update() {
        Array.prototype.forEach.call($slides, (el, i) => {
            el.classList.remove('active');

            if (
                (i > currentSlide - 3 && i < currentSlide + 3) ||
                (currentSlide < 3 && i > $slides.length - (3 - currentSlide)) ||
                (currentSlide > $slides.length - 3 && i < 3 - ($slides.length - currentSlide))
            ) {
                el.style.display = 'block';
            } else {
                el.style.display = 'none';
            }
        });

        // Set photo size
        resizeHandler();

        $slides[currentSlide].classList.add('active');

        if (!homepage && window.history && history.pushState) {
            // Update the url
            const newUrl = currentSlide > 0 ? `/${slug}/${currentSlide + 1}` : `/${slug}`;
            history.replaceState({}, '', newUrl);
        }
    }

    function goNext() {
        // Show the next one
        currentSlide++;
        if (currentSlide >= $slides.length) currentSlide = 0;
        update();
    }

    function goPrev() {
        // Show the next one
        currentSlide--;
        if (currentSlide < 0) currentSlide = $slides.length - 1;
        update();
    }

    if (homepage) {
        setInterval(goNext, 5000);
    } else {
        // Attach event listeners
        window.addEventListener('resize', resizeHandler, false);
        window.addEventListener('orientationchange', resizeHandler, false);
        Array.prototype.forEach.call($slides, (el) => {
            const $img = el.querySelector('img');
            if ($img) {
                $img.addEventListener('load', resizeHandler, false);
            }
        });

        // Pagination button event handlers
        $btnPrev.addEventListener('click', goPrev);
        $btnNext.addEventListener('click', goNext);
        $wrapper.addEventListener('click', goNext);

        // Swipes
        const hammer = new Hammer($wrapper);
        hammer.get('swipe').set({ direction: Hammer.DIRECTION_HORIZONTAL });
        hammer.on('swipeleft', goNext);
        hammer.on('swiperight', goPrev);

        // Arrow keys
        document.addEventListener('keydown', (e = window.event) => {
            const keyCode = e.which || e.keyCode;
            switch (keyCode) {
                case 37: // left
                    goPrev();
                    break;

                case 39: // right
                    goNext();
                    break;
            }

            e.preventDefault();
        });
    }

    // Show the first one to start off
    update();
}(document));
